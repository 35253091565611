@media (max-width: 767px) {
  .not-found-page {
    img {
      width: 175px;
    }
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
      margin-bottom: 12px;
    }
  }
}
