@media (max-width: 1199px) {
  header {
    padding: 24px 0;
    .head-left {
      margin-right: 30px;
    }
  }
}
@media (max-width: 767px) {
  header {
    padding: 12px 0;
    .mobile-menu-button {
      display: block;
    }
    .head-left {
      margin-right: 16px;
    }
    .search-field {
      input {
        padding: 0 16px 0 32px;
        background-position: left 10px center;
        background-size: 20px auto;
      }
    }
    .user-dropdown-box {
      padding-right: 18px;
      .user-info {
        .user-name {
          max-width: 100px;
        }
      }
    }
  }
}
