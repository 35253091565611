.user-dropdown {
  position: relative;
  &-box {
    cursor: pointer;
    padding-right: 40px;
    background-image: url('../../images/down-arrow.svg');
    background-repeat: no-repeat;
    background-position: center right;
    .user-image {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 1rem;
      img {
        display: block;
        width: 100%;
      }
    }
    .user-info {
      .user-name {
        color: $color-black;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        margin-bottom: 4px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user-type {
        color: $color-grey-90;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }
  &-body {
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0 10px 20px 0 rgba($color-black, 0.10);
    padding: 24px 32px;
    position: absolute;
    right: 0;
    width: 100%;
    min-width: 240px;
    top: 40px;
    z-index: 9;
    .sign-out-button {
      color: $color-grey-20;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      i {
        display: block;
        font-size: 1.625rem;
        margin-right: 20px;
      }
    }
  }
}
