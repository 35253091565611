.create-new {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    border-color: $color-light-grey;
    max-width: 100%;
    width: 400px;
  }
  &-top {
    border-bottom: 1px solid $color-light-grey;
    .switch {
      margin-bottom: 40px;
      p {
        color: #A6B6CD;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin-left: 10px;
      }
      .MuiSwitch-track {
        background: $color-grey-10;
        .MuiSwitch-thumb {
          box-shadow: none;
        }
        &.Mui-checked {
          background: $color-blue;
        }
      }
    }
  }
  &-bottom {
    padding: 40px 0;
    .MuiSelect-variantOutlined {
      width: 400px;
      max-width: 100%;
      border-color: $color-light-grey !important;
    }
    .field-divider {
      color: $color-grey-20;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.6875rem;
      margin: 30px 40px 0;
    }
    .save-button {
      max-width: 210px;
    }
    .add-folder-button {
      color: $color-blue;
      font-size: 18px;
      margin-left: 24px;
      margin-top: 44px;
    }
  }
}
