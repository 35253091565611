.navigation-item {
  a {
    color: $color-grey-20;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 24px;
    border-radius: 8px;
    i {
      font-size: 1.625rem;
      margin-right: 24px;
    }
  }
  &.active {
    a {
      color: $color-blue;
      background: $color-grey-10;
      position: relative;
      &:before {
        content: '';
        width: 4px;
        height: 20px;
        background-color: $color-blue;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 20px;
      }
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}
