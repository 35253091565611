@media (max-width: 767px) {

  .sign-page {
    padding: 40px 10px;
    &-content {
      padding: 36px 24px;
    }
  }

}
