@media (max-width: 1360px) {
  .create-new {
    .domain-fields {
      input[type='text'] {
        width: 300px;
      }
    }
    &-bottom {
      .field-divider {
        margin: 30px 20px 0;
      }
    }
  }
}
@media (max-width: 991px) {
  .create-new {
    .domain-fields {
      display: block;
      margin-bottom: 0;
      .input-group {
        margin-bottom: 40px;
      }
      input[type='text'] {
        width: 400px;
      }
    }
    &-bottom {
      .field-divider {
        display: none;
      }
    }
  }
}
@media (max-width: 767px) {
  .create-new {
    &-bottom {
      .folders-select-box {
        .select-group {
          max-width: calc(100% - 42px);
        }
      }
      .add-folder-button {
        margin-top: 37px;
      }
    }
  }
}
