@media (max-width: 1199px) {
  .folders {
    &-item {
      width: calc(33.3% - 8px);
      margin-right: 12px;
      margin-bottom: 12px;
      & > div {
        padding: 16px;
      }
      &:nth-child(4n) {
        margin-right: 12px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 991px) {
  .folders {
    &-item {
      width: calc(50% - 6px);
      &:nth-child(3n) {
        margin-right: 12px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 460px) {
  .folders {
    &-item {
      width: 100%;
      margin-right: 0 !important;
    }
  }
}
