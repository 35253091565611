.links {
  &-item {
    .title {
      padding-right: 110px;
    }
    .copy-url-button {
      padding: 4px 16px;
    }
  }
}
