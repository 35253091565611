@media (max-width: 1199px) {
  .dashboard {
    &-content {
      .dashboard-right {
        width: calc(100% - 230px);
        padding: 0 24px;
      }
    }
  }
}
@media (max-width: 767px) {
  .dashboard {
    &-content {
      .dashboard-right {
        width: 100%;
        padding: 0 12px;
      }
    }
  }
}
@media (min-width: 768px) {
  .dashboard {
    &-content {
      &.menu-open {
        height: auto;
        overflow: auto;
        &:before {
          display: none;
        }
      }
    }
  }
}
