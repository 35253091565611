.input-group {
  .field-title {
    display: block;
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 10px;
    b {
      color: $color-light-grey;
    }
  }
  label {
    display: block;
    position: relative;
    .error-message {
      position: absolute;
      bottom: -18px;
      left: 10px;
      color: $color-red-20;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.button-field {
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 0 60px 0 20px;
  }
  button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid $color-blue;
  padding: 0 20px;
  color: $color-black;
  font-size: 1.125rem;
  line-height: 1.65rem;
  transition: all .3s ease;
  &.error {
    border-color: $color-red;
    box-shadow: 0 0 4px 0 $color-light-red;
  }
  &:hover:not(.error) {
    box-shadow: 0 0 6px 0 $color-blue-10;
  }
  &:focus:not(.error) {
    box-shadow: 0 0 8px 0 $color-light-blue;
  }
}

.tags-box {
  max-width: 400px;
  .MuiGrid-container {
    .MuiGrid-direction-xs-row {
      .MuiChip-colorNeutral {
        padding: 4px 16px;
        border-radius: 4px;
        background: $color-grey-10;
        margin-top: 12px;
        .MuiChip-label {
          color: $color-black;
          font-size: 1.25rem;
          line-height: 1.6875rem;
          margin-right: 6px;
        }
        .MuiChipDelete-root {
          svg {
            color: $color-red-20;
          }
        }
      }
    }
  }
  .tags-info {
    color: #A6B6CD;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-top: 10px;
  }
}
