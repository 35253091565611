.sign-page {
  background: #F2F2F2;
  min-height: 100vh;
  padding: 40px 0;
  &-content {
    max-width: 576px;
    width: 100%;
    border-radius: 8px;
    background: $color-white;
    padding: 66px 88px;
  }
  h3 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 50px;
  }
  .input-group {
    margin-bottom: 30px;
  }
  .forgot-password-button {
    margin-bottom: 80px;
    a {
      color: $color-grey;
      font-size: 1.125rem;
      line-height: 1.65rem;
    }
  }
  .sing-form-more {
    color: $color-grey;
    font-size: 1.125rem;
    line-height: 1.65rem;
    margin-bottom: 24px;
    a {
      color: $color-blue-30;
      font-size: 1.125rem;
      line-height: 1.65rem;
    }
  }
  .primary-button {
    width: 100%;
  }
}
