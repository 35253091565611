h2 {
  color: $color-dark;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 2rem;
}
h3 {
  color: $color-dark;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.8125rem;
}
h4 {
  color: $color-dark;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.page-head {
  .sort-button {
    font-size: 2rem;
    color: $color-grey-20;
    transition: all .3s ease;
    &:hover {
      color: $color-blue;
    }
  }
}

.global-error-message {
  display: block;
  color: $color-red-20;
  font-size: 10px;
  line-height: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.MuiPagination-text {
  margin-top: 30px;
  padding: 0 40px;
  ul {
    li {
      button:not(.MuiPaginationItem-previousNext) {
        border-radius: 8px;
        background-color: transparent !important;
        color: $color-black;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        &.Mui-selected {
          border: 1px solid $color-grey-90;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        }
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100vh;
  & > div {
    width: 300px;
  }
}

.icon-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: rgba(150, 209, 255, 0.3);
  padding: 7px;
  margin-right: 8px;
  i {
    color: $color-blue;
    font-size: 24px;
  }
}

.MuiTooltip-popper {
  & > div {
    border-radius: 10px !important;
    background: $color-white !important;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10) !important;
    padding: 9px 16px !important;
    color: #4F4F4F !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}
