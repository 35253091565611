.select-group {
  position: relative;
  .field-title {
    display: block;
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 10px;
  }
  .error-message {
    position: absolute;
    bottom: -18px;
    left: 10px;
    color: $color-red-20;
    font-size: 10px;
    line-height: 14px;
  }
  .MuiSelect-variantOutlined {
    width: 400px;
    max-width: 100%;
  }
}

.MuiSelect-variantOutlined {
  width: 100%;
  height: 60px;
  border-radius: 8px !important;
  border: 1px solid #0085FF !important;
  padding: 0 20px;
  color: #000000;
  font-size: 1.125rem !important;
  line-height: 1.65rem !important;
  background: transparent !important;
  transition: all 0.3s ease;
  .MuiSelect-indicator {
    background-image: url('../../images/down-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 16px;
    height: 16px;
    svg {
      display: none;
    }
  }
  &.error {
    border-color: $color-red;
    box-shadow: 0 0 4px 0 $color-light-red;
  }
  &:hover {
    box-shadow: 0 0 6px 0 $color-blue-10;
  }
  &:focus {
    box-shadow: 0 0 8px 0 $color-light-blue;
  }
}
