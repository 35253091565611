@media (max-width: 520px) {
  .leads-buttons {
    flex-direction: column;
    .icon-tooltip {
      margin-right: 0 !important;
      margin-bottom: 5px;
    }
    & > button {
      margin-right: 0 !important;
      margin-left: 0 !important;
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }
  }
}
