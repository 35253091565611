.table {
  width: calc(100% + 80px);
  margin-left: -40px;
  overflow: auto;
  table {
    width: 100%;
    min-width: 1000px;
    thead {
      tr {
        th {
          min-width: 140px;
          background: $color-grey-10;
          color: $color-black;
          font-size: 1rem;
          line-height: 1.6875rem;
          font-weight: 400;
          text-align: center;
          padding: 10px;
          border: 1px solid $color-light-grey;
          white-space: nowrap;
          & > div {
            display: inline-block;
            border-radius: 8px;
            background: $color-white;
            padding: 2px 12px;
            white-space: nowrap;
            button {
              display: flex;
              align-items: center;
              font-size: 1rem;
              color: $color-blue;
              margin-left: 10px;
              &.sort-button {
                margin-left: 0;
                margin-right: 8px;
                &.asc {
                  transform: rotate(180deg);
                }
              }
            }
            &.open {
              background-color: $color-blue;
              color: $color-white;
              button {
                color: $color-white;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: $color-black;
          font-size: 1rem;
          line-height: 1.6875rem;
          padding: 16px 10px;
          text-align: center;
          border: 1px solid $color-light-grey;
          word-break: break-all;
          max-width: 280px;
          button {
            font-size: 1rem;
            line-height: 1.6875rem;
          }
          a {
            color: $color-blue;
          }
          & > div {
            white-space: nowrap;
            label {
              cursor: pointer;
              input[type="checkbox"] {
                display: none;
                &:checked {
                  & + b {
                    background-color: $color-blue;
                    border-color: $color-blue;
                    background-image: url('../../images/white-check-icon.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                  }
                }
              }
              b {
                display: block;
                width: 18px;
                height: 18px;
                border: 2px solid #A6B6CD;
                border-radius: 3px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
  .empty-text {
    color: #A6B6CD;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6875rem;
    padding: 0 40px;
  }
}


.search-popover {
  .MuiPaper-rounded {
    border-radius: 10px;
    background: $color-white;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);
    padding: 16px;
    input {
      width: 164px;
      height: 30px;
      padding: 2px 12px;
      border-radius: 8px;
      background: $color-grey-10;
      color: #A6B6CD;
      font-size: 0.9378rem;
      line-height: 1.6875rem;
      border: none;
    }
  }
}
