.textarea-group {
  .field-title {
    display: block;
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 10px;
    b {
      color: $color-light-grey;
    }
  }
  label {
    display: block;
    position: relative;
    .error-message {
      position: absolute;
      bottom: -18px;
      left: 10px;
      color: $color-red-20;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

textarea {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  border: 1px solid $color-blue;
  padding: 16px 20px;
  color: $color-black;
  font-size: 1.125rem;
  line-height: 1.65rem;
  font-family: $font-poppins;
  transition: all .3s ease;
  resize: none;
  &.error {
    border-color: $color-red;
    box-shadow: 0 0 4px 0 $color-light-red;
  }
  &:hover {
    box-shadow: 0 0 6px 0 $color-blue-10;
  }
  &:focus {
    box-shadow: 0 0 8px 0 $color-light-blue;
  }
}
