@media (max-width: 1199px) {
  .table {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
}
@media (max-width: 767px) {
  .table {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}
