.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(6px);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  &-inner {
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);
    position: relative;
    max-width: 90%;
  }
  &.verify-modal,
  &.delete-folder-modal,
  &.email-note-modal {
    .modal-inner {
      width: 534px;
      padding: 24px;
      h6 {
        display: flex;
        align-items: center;
        color: #0A0A0A;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-bottom: 10px;
        i {
          font-size: 1.5rem;
          color: $color-blue-30;
          margin-right: 16px;
        }
      }
      p {
        color: #4F4F4F;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin-bottom: 24px;
        padding-left: 40px;
        strong {
          font-size: 1.25rem;
          font-weight: 500;
        }
        button {
          font-size: 1.125rem;
          line-height: 1.6875rem;
          color: $color-light-blue;
        }
      }
    }
  }
  &.delete-folder-modal {
    .modal-inner {
      h6 {
        img {
          display: block;
          margin-right: 16px;
        }
      }
    }
  }
  &.create-folder-modal {
    .modal-inner {
      width: 534px;
      padding: 24px;
      h6 {
        color: $color-black;
        font-family: $font-roboto;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-bottom: 10px;
      }
    }
  }
  &.links-modal {
    .modal-inner {
      width: 534px;
      padding: 24px;
    }
  }
  &.description-modal {
    .modal-inner {
      width: 534px;
      padding: 24px;
      h6 {
        display: flex;
        align-items: center;
        color: #0A0A0A;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-bottom: 10px;
      }
      p {
        color: #4F4F4F;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin-bottom: 24px;
        word-break: break-all;
        strong {
          font-size: 1.25rem;
          font-weight: 500;
        }
        button {
          font-size: 1.125rem;
          line-height: 1.6875rem;
          color: $color-light-blue;
        }
      }
    }
  }
}
