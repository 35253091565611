.primary-button {
  color: $color-white;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  border-radius: 8px;
  border: 1px solid transparent;
  background: $color-blue-30;
  padding: 20px 36px;
  transition: all .3s ease;
  &.mini {
    width: auto;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding: 8px 16px;
  }
  &:hover:not(:disabled) {
    color: #0052BF;
    box-shadow: 0 0 6px 0 $color-blue-10;
  }
  &:focus {
    border-color: $color-blue-10;
    background: $color-blue;
    box-shadow: 0 0 6px 0 $color-blue-10;
    color: $color-white !important;
  }
  &:disabled,
  &.disabled {
    background: $color-light-grey;
    cursor: default;
  }
  &.secondary {
    background-color: #D2DBE7;
  }
  &.red {
    background-color: $color-red;
    &:hover:not(:disabled) {
      color: $color-white;
      box-shadow: 0 0 6px 0 $color-red;
    }
    &:focus {
      border-color: $color-red-20;
      background: $color-red-20;
      box-shadow: 0 0 6px 0 $color-red;
    }
  }
}
.outlined-button {
  color: $color-blue-30;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  border-radius: 8px;
  border: 1px solid $color-blue-30;
  background: transparent;
  padding: 20px 36px;
  transition: all .3s ease;
  &:hover:not(:disabled) {
    border-color: $color-blue-10;
    box-shadow: 0 0 6px 0 rgba(0, 110, 212, 0.50);
  }
  &:focus {
    border: 1px solid $color-blue;
    box-shadow: 0 0 6px 0 #006ED4;
    text-shadow: 1px 1px 2px rgba(0, 110, 212, 0.20);
  }
  &:disabled,
  &.disabled {
    background: $color-light-grey;
    border-color: $color-light-grey;
    cursor: default;
  }
}
