header {
  padding: 48px 0;
  .head-left {
    width: 100%;
    margin-right: 60px;
  }
  .back-button {
    font-size: 1.5rem;
    color: $color-grey-20;
    transition: all .3s ease;
    margin-right: 12px;
    &:hover {
      color: $color-blue;
    }
  }
  .mobile-menu-button {
    display: none;
    font-size: 20px;
    margin-right: 12px;
  }
  .search-field {
    width: 100%;
    input {
      border-radius: 8px;
      border: 1px solid $color-grey-90;
      color: $color-grey-90;
      font-size: 1.125rem;
      padding: 0 32px 0 64px;
      background-image: url('../../images/grey-search-icon.svg');
      background-repeat: no-repeat;
      background-position: left 24px center;
    }
  }
}
