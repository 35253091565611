@media (max-width: 1199px) {
  .sidebar {
    width: 230px;
    &-head {
      padding: 24px 16px;
      .logo {
        margin-bottom: 45px;
      }
    }
    &-body {
      padding: 24px 16px;
    }
  }
}
@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    z-index: 1;
    transform: translate(-100%);
    transition: all .3s ease;
    &-head {
      .mobile-close-menu {
        display: inline-block;
      }
    }
    &.open {
      transform: translate(0);
      z-index: 3;
    }
  }
}
