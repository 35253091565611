.not-found-page {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  padding: 0 16px;
  img {
    display: block;
    margin-bottom: 40px;
  }
  h1 {
    color: $color-dark;
    font-size: 3rem;
    font-weight: 600;
    line-height: 4.5rem;
  }
  p {
    color: #4F4F4F;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 30px;
  }
}
