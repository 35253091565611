@media (max-width: 1199px) {
  html,
  body {
    font-size: 14px;
  }
  .primary-button,
  .outlined-button {
    padding: 16px 20px;
  }

  .MuiPagination-text {
    padding: 0 12px;
  }
}
@media (max-width: 767px) {
  html,
  body {
    font-size: 12px;
  }

  input[type=text],
  input[type=email],
  input[type=password] {
    height: 50px;
    padding: 0 16px;
  }

  .MuiSelect-variantOutlined {
    height: 50px;
    padding: 0 16px;
  }


  .MuiPagination-text {
    padding: 0;
  }
}
