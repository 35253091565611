.folders {
  &-list {
    flex-wrap: wrap;
  }
  &-item {
    width: calc(25% - 13.5px);
    margin-right: 18px;
    margin-bottom: 18px;
    & > div {
      height: 145px;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid $color-grey-90;
      background: #FAFCFF;
      padding: 24px;
      .title {
        color: $color-black;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.875rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        .email {
          font-size: 1rem;
        }
      }
      .delete-button {
        i {
          font-size: 1.75rem;
          color: $color-grey-20;
        }
      }
      .count {
        color: $color-grey-20;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.875rem;
      }
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  .empty-text {
    color: #A6B6CD;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6875rem;
  }
}
