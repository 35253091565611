.links {
  &-item {
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #FAFCFF;
      border: 1px solid #B6C3D6;
      margin-right: 24px;
    }
    .title {
      color: $color-black;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
      margin-bottom: 12px;
      padding-right: 145px;
      word-break: break-all;
    }
    .url {
      color: $color-blue;
      font-size: 1rem;
      line-height: 1.5rem;
      word-break: break-all;
    }
    .old-url {
      color: $color-black;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 8px;
      margin-bottom: 20px;
      word-break: break-all;
    }
    .copy-url-button {
      position: absolute;
      border-radius: 4px;
      background: $color-grey-10;
      padding: 4px 32px;
      color: $color-black;
      font-size: 1.125rem;
      line-height: 1.6875rem;
      right: 24px;
      top: 24px;
    }
  }
}
