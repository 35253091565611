input::-webkit-input-placeholder {
  color: $color-light-grey;
}
input::-moz-placeholder {
  color: $color-light-grey;
}
input:-ms-input-placeholder {
  color: $color-light-grey;
}
input:-moz-placeholder {
  color: $color-light-grey;
}

textarea::-webkit-input-placeholder {
  color: $color-light-grey;
}
textarea::-moz-placeholder {
  color: $color-light-grey;
}
textarea:-ms-input-placeholder {
  color: $color-light-grey;
}
textarea:-moz-placeholder {
  color: $color-light-grey;
}

input:focus::-webkit-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus:-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus::-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
input:focus:-ms-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus::-webkit-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus:-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus::-moz-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
textarea:focus:-ms-input-placeholder {
  transition: all 0.3s ease;
  opacity: 0;
}
