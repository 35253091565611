// Fonts
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;

// Font Sizes
$font-size-base: 16px !default;

// Colors
$color-black: #000000 !default;
$color-white: #ffffff !default;
$color-dark: #333333 !default;
$color-blue: #0085FF !default;
$color-light-blue: #4E9BFD !default;
$color-blue-30: #359EFF !default;
$color-blue-20: #61B9FF !default;
$color-blue-10: #96D1FF !default;
$color-grey: #828282 !default;
$color-grey-90: #B6C3D6 !default;
$color-grey-20: #7D8B9F !default;
$color-grey-10: #EDF2F9 !default;
$color-light-grey: #D2DBE7 !default;
$color-red: #F00 !default;
$color-red-20: #FF4B4B !default;
$color-light-red: rgba(190, 65, 65, 0.50) !default;


$colors: (
        'black': $color-black,
        'white': $color-white,
        'blue': $color-blue,
        'grey': $color-grey,
        'light-grey': $color-light-grey,
        'red': $color-red,
) !default;

@each $color, $value in $colors {
  .text-color-#{$color} {
    color: $value;
  }
}
@each $color, $value in $colors {
  .bg-color-#{$color} {
    background-color: $value;
  }
}

