.sidebar {
  width: 280px;
  min-height: 100vh;
  border-right: 1px solid $color-light-grey;
  background-color: $color-white;
  &-head {
    padding: 46px 26px 35px;
    border-bottom: 1px solid $color-light-grey;
    position: relative;
    .logo {
      display: inline-block;
      color: $color-dark;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 55px;
    }
    button {
      width: 100%;
    }
    .mobile-close-menu {
      display: none;
      position: absolute;
      right: 24px;
      top: 28px;
      width: auto;
      font-size: 20px;
    }
  }
  &-body {
    padding: 30px 26px;
  }
}
