.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
