.dashboard-content {
  .dashboard-right {
    width: calc(100% - 280px);
    padding: 0 40px;
    .dashboard-body {
      padding: 20px 0;
    }
  }
  &.menu-open {
    height: 100vh;
    overflow: hidden;
    &:before {
      content: '';
      width: 100%;
      height: 100vh;
      background-color: rgba(0,0,0,.5);
      position: absolute;
      z-index: 2;
    }
  }
}
